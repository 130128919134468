import _ from "lodash";

import { getDefaultSelection } from "~/shared/components/Bulk/BulkBar/BulkBar";

export const DIVING_LIST = [
    { key: "H1", value: "1 M Diving" },
    { key: "H3", value: "3 M Diving" },
    { key: "H2", value: "Platform Diving" },
];

export const DIVING_CHOICES = ["5", "6", "B"];

export const EVENTS_25 = [
    { key: "125", value: "25 Free" },
    { key: "225", value: "25 Back" },
    { key: "325", value: "25 Breast" },
    { key: "425", value: "25 Fly" },
];

export const AGEUP_STRATEGIES = [
    { key: "dos", value: "Date of Swim" },
    { key: "year", value: "Start of the year" },
    { key: "midyear", value: "Middle of the year" },
    { key: "meet", value: "Start of the meet" },
];

export const INDIVIDUAL_LIST = [
    { key: "150", value: "50 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1400", value: "400 Free" },
    { key: "1500", value: "500 Free" },
    { key: "1800", value: "800 Free" },
    { key: "11000", value: "1000 Free" },
    { key: "11500", value: "1500 Free" },
    { key: "11650", value: "1650 Free" },
    { key: "250", value: "50 Back" },
    { key: "2100", value: "100 Back" },
    { key: "2200", value: "200 Back" },
    { key: "350", value: "50 Breast" },
    { key: "3100", value: "100 Breast" },
    { key: "3200", value: "200 Breast" },
    { key: "450", value: "50 Fly" },
    { key: "4100", value: "100 Fly" },
    { key: "4200", value: "200 Fly" },
    { key: "5100", value: "100 IM" },
    { key: "5200", value: "200 IM" },
    { key: "5400", value: "400 IM" },
];

export const FULL_INDIVIDUAL_LIST = _.orderBy(
    [...INDIVIDUAL_LIST, ...EVENTS_25],
    [
        (option) => Number(option.key[0]), // stroke
        (option) => Number(option.key.slice(1)), // distance
    ],
);

export const RELAY_LIST = [
    { key: "6200", value: "200 Free Relay" },
    { key: "6400", value: "400 Free Relay" },
    { key: "6800", value: "800 Free Relay" },
    { key: "7200", value: "200 Medley Relay" },
    { key: "7400", value: "400 Medley Relay" },
];

export const SPLIT_LIST = [
    { key: "split_150", value: "50 Free Relay Split" },
    { key: "split_1100", value: "100 Free Relay Split" },
    { key: "split_1200", value: "200 Free Relay Split" },
    { key: "split_250", value: "50 Back Relay Split" },
    { key: "split_350", value: "50 Breast Relay Split" },
    { key: "split_3100", value: "100 Breast Relay Split" },
    { key: "split_450", value: "50 Fly Relay Split" },
    { key: "split_4100", value: "100 Fly Relay Split" },
];

export const EVENT_LIST = [
    ...INDIVIDUAL_LIST,
    ...RELAY_LIST,
    ...DIVING_LIST,
    ...SPLIT_LIST,
];

export const SEARCH_DELAY = 300;

export const COUNTRY_CHOICES = [
    { key: "USA", value: "United States" },
    { key: "AFG", value: "Afghanistan" },
    { key: "ALB", value: "Albania" },
    { key: "ALG", value: "Algeria" },
    { key: "ASA", value: "American Samoa" },
    { key: "AND", value: "Andorra" },
    { key: "ANG", value: "Angola" },
    { key: "ANT", value: "Antigua & Barbuda" },
    { key: "ANL", value: "Antilles" },
    { key: "ARG", value: "Argentina" },
    { key: "ARM", value: "Armenia" },
    { key: "ABW", value: "Aruba" },
    { key: "AUS", value: "Australia" },
    { key: "AUT", value: "Austria" },
    { key: "AZE", value: "Azerbaijan" },
    { key: "BAH", value: "Bahamas" },
    { key: "BRN", value: "Bahrain" },
    { key: "BAN", value: "Bangladesh" },
    { key: "BAR", value: "Barbados" },
    { key: "BLR", value: "Belarus" },
    { key: "BEL", value: "Belgium" },
    { key: "BIZ", value: "Belize" },
    { key: "BEN", value: "Benin" },
    { key: "BER", value: "Bermuda" },
    { key: "BHU", value: "Bhutan" },
    { key: "BOH", value: "Bohemia" },
    { key: "BOL", value: "Bolivia" },
    { key: "BIH", value: "Bosnia-Herzegovina" },
    { key: "BOT", value: "Botswana" },
    { key: "BRA", value: "Brazil" },
    { key: "IVB", value: "British Virgin Islands" },
    { key: "BWI", value: "British West Indies" },
    { key: "BRU", value: "Brunei" },
    { key: "BUL", value: "Bulgaria" },
    { key: "BUR", value: "Burkina Faso" },
    { key: "BIR", value: "Burma" },
    { key: "BDI", value: "Burundi" },
    { key: "CAM", value: "Cambodia" },
    { key: "CMR", value: "Cameroon" },
    { key: "CAN", value: "Canada" },
    { key: "CPV", value: "Cape Verde" },
    { key: "CAY", value: "Cayman Islands" },
    { key: "CAF", value: "Central African Republic" },
    { key: "CEY", value: "Ceylon" },
    { key: "CHA", value: "Chad" },
    { key: "CHI", value: "Chile" },
    { key: "CHN", value: "China" },
    { key: "TPE", value: "Chinese Taipei" },
    { key: "COL", value: "Colombia" },
    { key: "COM", value: "Comoros Islands" },
    { key: "CGO", value: "Congo" },
    { key: "COK", value: "Cook Islands" },
    { key: "CRC", value: "Costa Rica" },
    { key: "CRO", value: "Croatia" },
    { key: "CUB", value: "Cuba" },
    { key: "CUR", value: "Curaçao" },
    { key: "CYP", value: "Cyprus" },
    { key: "CZE", value: "Czech Republic" },
    { key: "DEN", value: "Denmark" },
    { key: "DJI", value: "Djibouti" },
    { key: "DMA", value: "Dominica" },
    { key: "DOM", value: "Dominican Republic" },
    { key: "ECU", value: "Ecuador" },
    { key: "EGY", value: "Egypt" },
    { key: "ESA", value: "El Salvador" },
    { key: "ENG", value: "England" },
    { key: "GEQ", value: "Equatorial Guinea" },
    { key: "ERI", value: "Eritrea" },
    { key: "EST", value: "Estonia" },
    { key: "ETH", value: "Ethiopia" },
    { key: "FAR", value: "Faroe Islands" },
    { key: "FIJ", value: "Fiji" },
    { key: "FIN", value: "Finland" },
    { key: "FOR", value: "Formosa" },
    { key: "FRA", value: "France" },
    { key: "GAB", value: "Gabon" },
    { key: "GAM", value: "Gambia" },
    { key: "GEO", value: "Georgia" },
    { key: "GER", value: "Germany" },
    { key: "GHA", value: "Ghana" },
    { key: "GIB", value: "Gibraltar" },
    { key: "GBR", value: "Great Britain" },
    { key: "GRE", value: "Greece" },
    { key: "GRN", value: "Grenada" },
    { key: "GUM", value: "Guam" },
    { key: "GUA", value: "Guatemala" },
    { key: "GUI", value: "Guinea" },
    { key: "GBS", value: "Guinea-Bissau" },
    { key: "GUY", value: "Guyana" },
    { key: "HAI", value: "Haiti" },
    { key: "HAW", value: "Hawaii" },
    { key: "HON", value: "Honduras" },
    { key: "HKG", value: "Hong Kong" },
    { key: "HUN", value: "Hungary" },
    { key: "ISL", value: "Iceland" },
    { key: "IND", value: "India" },
    { key: "INA", value: "Indonesia" },
    { key: "IRI", value: "Iran" },
    { key: "IRQ", value: "Iraq" },
    { key: "IRL", value: "Ireland" },
    { key: "ISR", value: "Israel" },
    { key: "ITA", value: "Italy" },
    { key: "CIV", value: "Ivory Coast" },
    { key: "JAM", value: "Jamaica" },
    { key: "JPN", value: "Japan" },
    { key: "JOR", value: "Jordan" },
    { key: "KAZ", value: "Kazakhstan" },
    { key: "KEN", value: "Kenya" },
    { key: "KIR", value: "Kiribati" },
    { key: "KOR", value: "Korea (South)" },
    { key: "KUW", value: "Kuwait" },
    { key: "KGZ", value: "Kyrgyzstan" },
    { key: "LAO", value: "Laos" },
    { key: "LAT", value: "Latvia" },
    { key: "LIB", value: "Lebanon" },
    { key: "LES", value: "Lesotho" },
    { key: "LBR", value: "Liberia" },
    { key: "LBA", value: "Libya" },
    { key: "LIE", value: "Liechtenstein" },
    { key: "LTU", value: "Lithuania" },
    { key: "LUX", value: "Luxembourg" },
    { key: "MAC", value: "Macau" },
    { key: "MKD", value: "Macedonia" },
    { key: "MAD", value: "Madagascar" },
    { key: "MAW", value: "Malawi" },
    { key: "MAS", value: "Malaysia" },
    { key: "MDV", value: "Maldives" },
    { key: "MLI", value: "Mali" },
    { key: "MLT", value: "Malta" },
    { key: "NMA", value: "Mariannes Islands" },
    { key: "MIL", value: "Marshall Islands" },
    { key: "MTN", value: "Mauritania" },
    { key: "MRI", value: "Mauritius" },
    { key: "MEX", value: "Mexico" },
    { key: "FSM", value: "Micronesia" },
    { key: "MDA", value: "Moldova" },
    { key: "MON", value: "Monaco" },
    { key: "MGL", value: "Mongolia" },
    { key: "MNE", value: "Montenegro" },
    { key: "MAR", value: "Morocco" },
    { key: "MOZ", value: "Mozambique" },
    { key: "MYA", value: "Myanmar" },
    { key: "NAM", value: "Namibia" },
    { key: "NRU", value: "Nauru" },
    { key: "NEP", value: "Nepal" },
    { key: "NED", value: "Netherlands" },
    { key: "AHO", value: "Netherlands Antilles" },
    { key: "NZL", value: "New Zealand" },
    { key: "NCA", value: "Nicaragua" },
    { key: "NIG", value: "Niger" },
    { key: "NGR", value: "Nigeria" },
    { key: "NIR", value: "Northern Ireland" },
    { key: "NOR", value: "Norway" },
    { key: "OMA", value: "Oman" },
    { key: "PAK", value: "Pakistan" },
    { key: "PLW", value: "Palau" },
    { key: "PLE", value: "Palestine" },
    { key: "PAN", value: "Panama" },
    { key: "PNG", value: "Papua New Guinea" },
    { key: "PAR", value: "Paraguay" },
    { key: "PER", value: "Peru" },
    { key: "PHI", value: "Philippines" },
    { key: "POL", value: "Poland" },
    { key: "POR", value: "Portugal" },
    { key: "PUR", value: "Puerto Rico" },
    { key: "QAT", value: "Qatar" },
    { key: "RHO", value: "Rhodesia" },
    { key: "ROU", value: "Romania" },
    { key: "RUS", value: "Russia" },
    { key: "RWA", value: "Rwanda" },
    { key: "LCA", value: "Saint Lucia" },
    { key: "SAM", value: "Samoa" },
    { key: "SMR", value: "San Marino" },
    { key: "STP", value: "Sao Tome & Principe" },
    { key: "KSA", value: "Saudi Arabia" },
    { key: "SCO", value: "Scotland" },
    { key: "SEN", value: "Senegal" },
    { key: "SRB", value: "Serbia" },
    { key: "SCG", value: "Serbia & Montenegro" },
    { key: "SEY", value: "Seychelles" },
    { key: "SLE", value: "Sierra Leone" },
    { key: "SIN", value: "Singapore" },
    { key: "SVK", value: "Slovakia" },
    { key: "SLO", value: "Slovenia" },
    { key: "SOL", value: "Solomon Islands" },
    { key: "SOM", value: "Somalia" },
    { key: "RSA", value: "South Africa" },
    { key: "ESP", value: "Spain" },
    { key: "SRI", value: "Sri Lanka" },
    { key: "SKN", value: "St. Kitts & Nevis" },
    { key: "VIN", value: "St. Vincent & Grenadines" },
    { key: "SUD", value: "Sudan" },
    { key: "SUR", value: "Suriname" },
    { key: "SWZ", value: "Swaziland" },
    { key: "SWE", value: "Sweden" },
    { key: "SUI", value: "Switzerland" },
    { key: "SYR", value: "Syria" },
    { key: "TAH", value: "Tahiti" },
    { key: "TJK", value: "Tajikistan" },
    { key: "TAN", value: "Tanzania" },
    { key: "THA", value: "Thailand" },
    { key: "TOG", value: "Togo" },
    { key: "TGA", value: "Tonga" },
    { key: "TRI", value: "Trinidad & Tobago" },
    { key: "TUN", value: "Tunisia" },
    { key: "TUR", value: "Turkey" },
    { key: "TKM", value: "Turkmenistan" },
    { key: "UGA", value: "Uganda" },
    { key: "UKR", value: "Ukraine" },
    { key: "UAE", value: "United Arab Emirates" },
    { key: "URU", value: "Uruguay" },
    { key: "UZB", value: "Uzbekistan" },
    { key: "VAN", value: "Vanuatu" },
    { key: "VEN", value: "Venezuela" },
    { key: "VIE", value: "Vietnam" },
    { key: "ISV", value: "Virgin Islands" },
    { key: "WAL", value: "Wales" },
    { key: "YEM", value: "Yemen" },
    { key: "TMD", value: "Yemen, PDR" },
    { key: "ZAI", value: "Zaire" },
    { key: "ZAM", value: "Zambia" },
    { key: "ZIM", value: "Zimbabwe" },
];

export const COUNTRIES_WITH_STATES = [
    { key: "USA", value: "United States" },
    { key: "CAN", value: "Canada" },
    { key: "MEX", value: "Mexico" },
    { key: "RSA", value: "South Africa" },
];

export const COUNTRY_LABELS = _.fromPairs(
    COUNTRY_CHOICES.map(({ key, value }) => [key, value]),
);

export const STATE_CHOICES = [
    { key: "", value: "-------" },
    { key: "AL", value: "Alabama" },
    { key: "AK", value: "Alaska" },
    { key: "AZ", value: "Arizona" },
    { key: "AR", value: "Arkansas" },
    { key: "CA", value: "California" },
    { key: "CO", value: "Colorado" },
    { key: "CT", value: "Connecticut" },
    { key: "DE", value: "Delaware" },
    { key: "DC", value: "D.C." },
    { key: "FL", value: "Florida" },
    { key: "GA", value: "Georgia" },
    { key: "HI", value: "Hawaii" },
    { key: "ID", value: "Idaho" },
    { key: "IL", value: "Illinois" },
    { key: "IN", value: "Indiana" },
    { key: "IA", value: "Iowa" },
    { key: "KS", value: "Kansas" },
    { key: "KY", value: "Kentucky" },
    { key: "LA", value: "Louisiana" },
    { key: "ME", value: "Maine" },
    { key: "MD", value: "Maryland" },
    { key: "MA", value: "Massachusetts" },
    { key: "MI", value: "Michigan" },
    { key: "MN", value: "Minnesota" },
    { key: "MS", value: "Mississippi" },
    { key: "MO", value: "Missouri" },
    { key: "MT", value: "Montana" },
    { key: "NE", value: "Nebraska" },
    { key: "NV", value: "Nevada" },
    { key: "NH", value: "New Hampshire" },
    { key: "NJ", value: "New Jersey" },
    { key: "NM", value: "New Mexico" },
    { key: "NY", value: "New York" },
    { key: "NC", value: "North Carolina" },
    { key: "ND", value: "North Dakota" },
    { key: "OH", value: "Ohio" },
    { key: "OK", value: "Oklahoma" },
    { key: "OR", value: "Oregon" },
    { key: "PA", value: "Pennsylvania" },
    { key: "RI", value: "Rhode Island" },
    { key: "SC", value: "South Carolina" },
    { key: "SD", value: "South Dakota" },
    { key: "TN", value: "Tennessee" },
    { key: "TX", value: "Texas" },
    { key: "UT", value: "Utah" },
    { key: "VT", value: "Vermont" },
    { key: "VA", value: "Virginia" },
    { key: "WA", value: "Washington" },
    { key: "WV", value: "West Virginia" },
    { key: "WI", value: "Wisconsin" },
    { key: "WY", value: "Wyoming" },
];

export const CANADA_STATE_CHOICES = [
    { key: "", value: "-------" },
    { key: "AB", value: "Alberta" },
    { key: "BC", value: "British Columbia" },
    { key: "MB", value: "Manitoba" },
    { key: "NB", value: "New Brunswick" },
    { key: "NL", value: "Newfoundland and Labrador" },
    { key: "NS", value: "Nova Scotia" },
    { key: "NT", value: "Northwest Territories" },
    { key: "NU", value: "Nunavut" },
    { key: "ON", value: "Ontario" },
    { key: "PE", value: "Prince Edward Island" },
    { key: "QC", value: "Quebec" },
    { key: "SK", value: "Saskatchewan" },
    { key: "YT", value: "Yukon" },
];

export const EVENT_COURSE_LABELS = {
    Y: "SCY (25 yards)",
    S: "SCM (25 meters)",
    L: "LCM (50 meters)",
    Z: "XCY (20 yards)",
    O: "Open Water",
};
export const DIVE_COURSE_LABELS = {
    5: "5 Dives",
    6: "6 Dives",
    B: "11 Dives",
};
export const ALL_COURSE_LABELS = {
    ...EVENT_COURSE_LABELS,
    ...DIVE_COURSE_LABELS,
};

export const EVENT_COURSE_CHOICES = [
    { key: "Y", value: "SCY (25 yards)" },
    { key: "S", value: "SCM (25 meters)" },
    { key: "L", value: "LCM (50 meters)" },
    { key: "O", value: "Open Water" },
];
export const DIVE_COURSE_CHOICES = [
    { key: "5", value: "5 Dives" },
    { key: "6", value: "6 Dives" },
    { key: "B", value: "11 Dives" },
];
export const ALL_COURSE_CHOICES = [
    ...EVENT_COURSE_CHOICES,
    ...DIVE_COURSE_CHOICES,
];
// rare courses
export const Z_COURSE_CHOICE = { key: "Z", value: "XCY (20 yards)" };

export const EVENT_COURSE_SHORT_LABELS = {
    Y: "SCY",
    S: "SCM",
    L: "LCM",
    Z: "XCY",
    O: "OPN",
};
export const DIVE_COURSE_SHORT_LABELS = {
    5: "5 Dives",
    6: "6 Dives",
    B: "11 Dives",
};
export const ALL_COURSE_SHORT_LABELS = {
    ...EVENT_COURSE_SHORT_LABELS,
    ...DIVE_COURSE_SHORT_LABELS,
};

export const EVENT_COURSE_SHORT_CHOICES = [
    { key: "Y", value: "SCY" },
    { key: "S", value: "SCM" },
    { key: "L", value: "LCM" },
    { key: "O", value: "OPN" },
];
export const DIVE_COURSE_SHORT_CHOICES = [
    { key: "5", value: "5 Dives" },
    { key: "6", value: "6 Dives" },
    { key: "B", value: "11 Dives" },
];
// rare courses
export const Z_COURSE_SHORT_CHOICE = { key: "Z", value: "XCY" };

export const MEET_SIZE_CHOICES = [
    { key: "1", value: "Intrasquad" },
    { key: "2", value: "Dual Meet" },
    { key: "4", value: "Invite/Tournament" },
    { key: "6", value: "Championship" },
    { key: "10", value: "Time Trials" },
];
export const MEET_SIZE_MAP = {
    1: "Intrasquad",
    2: "Dual meet",
    4: "Invite/Tournament",
    6: "Championship",
    10: "Time trials",
};

export const MEET_TYPE_ABOUT_CHOICES = [
    {
        key: "8",
        value: "International Championship",
    },
    {
        key: "120",
        value: "Conference Championship",
    },
    {
        key: "210",
        value: "National Championship",
    },
    {
        key: "230",
        value: "Junior Championship",
    },
    {
        key: "250",
        value: "Sectional Championship",
    },
    {
        key: "260",
        value: "State Championship",
    },
    {
        key: "320",
        value: "Sectional/Regional/District",
    },
];

export const MEET_TYPE_CHOICES = [
    {
        key: "010",
        value: "International Championship",
        orgcodes: [8, 1],
    },
    {
        key: "120",
        value: "Conference Championship",
        orgcodes: [3],
    },
    {
        key: "210",
        value: "National Championship",
        orgcodes: [8, 1, 3],
    },
    {
        key: "230",
        value: "Junior Championship",
        orgcodes: [8, 1],
    },
    {
        key: "250",
        value: "Sectional Championship",
        orgcodes: [1],
    },
    {
        key: "260",
        value: "State Championship",
        orgcodes: [1, 9],
    },
    {
        key: "320",
        value: "Sectional/Regional/District",
        orgcodes: [9],
    },
];

export const FULL_STROKE_LABELS = {
    1: "Freestyle",
    2: "Backstroke",
    3: "Breaststroke",
    4: "Butterfly",
    5: "Individual Medley",
    6: "Freestyle Relay",
    7: "Medley Relay",
    H: "M Diving",
};

export const STROKE_LABELS = {
    1: "Free",
    2: "Back",
    3: "Breast",
    4: "Fly",
    5: "IM",
    6: "Free Relay",
    7: "Medley Relay",
    H: "M Diving",
};

export const ALIKE_STROKES_SETS = [
    new Set(["1", "2", "3", "4", "5"]),
    new Set(["6", "7"]),
    new Set(["H"]),
];

export const GENDER_LABELS = {
    M: "Men",
    F: "Women",
    X: "Mixed",
    C: "Both",
};
export const GENDER_LABELS_ALT = {
    M: "Men",
    F: "Women",
    X: "Mixed",
    C: "Combined",
};
export const ABOUT_FORM_GENDER_OPTIONS = [
    { key: "M", value: "Men" },
    { key: "F", value: "Women" },
    { key: "X", value: "Men/Women" },
];
export const GENDER_OPTIONS = [
    { key: "M", value: "Men" },
    { key: "F", value: "Women" },
];
// rare genders
export const X_GENDER_OPTION = { key: "X", value: "Mixed" };
export const C_GENDER_OPTION = { key: "C", value: "Both" };
export const C_GENDER_OPTION_ALT = { key: "C", value: "Combined" };

export const RANK_TYPE_OPTIONS = [
    { key: "D", value: "Dual" },
    { key: "C", value: "Championship" },
];

export const MEET_ABOUT_ORGCODE_CHOICES = [
    { key: "1", value: "Club" },
    { key: "3", value: "College" },
    { key: "9", value: "High School" },
    { key: "2", value: "Masters" },
    { key: "8", value: "International" },
    { key: "5", value: "College Club" },
];

export const SUB_ORGCODES = [
    { key: "1", value: "USA Swimming" },
    // {key: "2", value: "Masters"},
    { key: "3", value: "EFSL" },
    { key: "4", value: "Summer League" },
    { key: "5", value: "Collegiate Club" },
    { key: "7", value: "YMCA" },
    { key: "8", value: "AAU" },
    { key: "9", value: "Middle School" },
];

export const TEAM_ORGCODE_CHOICES = [
    { key: 1, value: "Club Team" },
    { key: 9, value: "High School Team" },
    { key: 3, value: "College" },
    { key: 2, value: "Masters" },
];

export const MIXED_ORGCODE_CHOICES = [
    { key: "1-1", value: "USA Swimming" },
    { key: "3", value: "College" },
    { key: "9", value: "High School" },
    { key: "1-9", value: "Middle School" },
    { key: "1-4", value: "Summer League" },
    { key: "1-5", value: "Collegiate Club" },
    { key: "1-7", value: "YMCA" },
    { key: "1-3", value: "EFSL" },
    { key: "1-8", value: "AAU" },
    { key: "2", value: "Masters" },
    // { key: "5", value: "College Club" },
    { key: "8", value: "International" },
];

export const SWIMMER_MIXED_ORGCODE_CHOICES = [
    { key: "1-1", value: "USA Swimming" },
    { key: "9", value: "High School" },
    { key: "1-9", value: "Middle School" },
    { key: "1-4", value: "Summer League" },
    { key: "1-5", value: "Collegiate Club" },
    { key: "1-7", value: "YMCA" },
    { key: "1-3", value: "EFSL" },
    { key: "1-8", value: "AAU" },
    { key: "2", value: "Masters" },
];

export const COMBINED_MEET_ORGCODE_CHOICES = Array.from(
    new Set(MEET_ABOUT_ORGCODE_CHOICES.concat(MIXED_ORGCODE_CHOICES)),
);

export const MEET_NAME_BLACK_LIST = [
    "test",
    "Test",
    "converted",
    "Converted",
    "swimmername",
];

export const START_ALTERNATES_FROM = 20;

export const WORLDWIDE_REGION = {
    id: 1,
    abbr: "Worldwide",
    slug: "global",
    choice_value: "global",
    name: "Worldwide",
    display_name: "Worldwide",
    logo_url: "/media/img/swimcloud/regions/globe.svg",
    breadcrumbs: "",
    category: "global",
    category_label: "Worldwide",
    orgcode: null,
    show_age_group_filter: false,
    course_choices: [],
    rank_type_choices: [],
    agegroup_choices: [],
};

export const USACOLLEGE_CHOICE_VALUE = "countryorganisation_usacollege";

export const EVENTROUND_CHOICES = [
    { key: "TIM", value: "Timed Finals" },
    { key: "PRE", value: "Prelims" },
    { key: "FIN", value: "Finals" },
    { key: "SEM", value: "Semifinals" },
    { key: "QUA", value: "Quarterfinals" },
    { key: "OCT", value: "Octofinals" },
    { key: "SOF", value: "Swimoff" },
    { key: "SOP", value: "Prelims Swimoff" },
    { key: "SOS", value: "Semifinals Swimoff" },
    { key: "SOQ", value: "Quarterfinals Swimoff" },
    { key: "SOO", value: "Octofinals Swimoff" },
];

export const EVENTROUND_MAP = {
    TIM: "Timed Finals",
    FIN: "Finals",
    SEM: "Semifinals",
    QUA: "Quarterfinals",
    OCT: "Octofinals",
    PRE: "Prelims",
    SOF: "Swimoff",
    SOP: "Prelims Swimoff",
    SOS: "Semifinals Swimoff",
    SOQ: "Quarterfinals Swimoff",
    SOO: "Octofinals Swimoff",
};

export const EVENTROUND_LABELS = _.fromPairs(
    EVENTROUND_CHOICES.map((choice) => [choice.key, choice.value]),
);

export const HEAT_LABELS = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

export const HEAT_CHOICES = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
    { key: "12", value: "12" },
    { key: "13", value: "13" },
    { key: "14", value: "14" },
    { key: "15", value: "15" },
    { key: "16", value: "16" },
    { key: "17", value: "17" },
    { key: "18", value: "18" },
    { key: "19", value: "19 " },
    { key: "20", value: "20" },
];

export const LANE_CHOICES = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
    { key: "12", value: "12" },
];

export const SEED_ORDER_CHOICES = [
    { key: "STF", value: "Slow to fast" },
    { key: "FTS", value: "Fast to slow" },
];

export const SEED_PREFERENCES_OPTIONS = [
    { key: "TB", value: "Time-based" },
    { key: "LB", value: "Lane-based" },
];

export const NUMROUND_CHOICES = [
    { key: "1", value: "Timed Finals" },
    { key: "2", value: "Prelims/Finals" },
    { key: "3", value: "Prelims/Semifinals/Finals" },
    { key: "4", value: "Time Trial" },
];

export const SPEED_OPTIONS = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2];

export const CAPTURED_AT_RACE = 1;
export const CAPTURED_AT_PRACTICE = 2;
export const CAPTURED_AT_OPTIONS = [
    { key: "1", value: "Race" },
    { key: "2", value: "Practice" },
];

export const TIMECODE_MAP = {
    1: "NT",
    2: "NS",
    3: "DNF",
    4: "DQ",
    // 5: "SCR",
    6: "DFS",
};
export const TIMECODE_CHOICES = [
    { key: "1", value: "No Time" },
    { key: "2", value: "No Swim" },
    { key: "3", value: "Did Not Finish" },
    { key: "4", value: "Disqualified" },
    // { key: "5", value: "Scratched" },
    { key: "6", value: "DFS" },
];
export const INVERTED_TIMECODE_MAP = _.invert(TIMECODE_MAP);

export const AGE_GROUP_OPTIONS = [
    {
        min_age: null,
        max_age: null,
        eventage: "UNOV",
        label: "Open",
        abbr: "Open",
    },
    {
        min_age: null,
        max_age: 8,
        eventage: "UN08",
        label: "8 and under",
        abbr: "8-",
    },
    {
        min_age: 9,
        max_age: 10,
        eventage: "0910",
        label: "9 - 10",
        abbr: "9-10",
    },
    {
        min_age: 11,
        max_age: 12,
        eventage: "1112",
        label: "11 - 12",
        abbr: "11-12",
    },
    {
        min_age: 13,
        max_age: 14,
        eventage: "1314",
        label: "13 - 14",
        abbr: "13-14",
    },
    {
        min_age: 15,
        max_age: null,
        eventage: "15OV",
        label: "15 and over",
        abbr: "15+",
    },
    {
        min_age: 25,
        max_age: 29,
        eventage: "2529",
        label: "25 - 29",
        abbr: "25-29",
    },
    {
        min_age: 30,
        max_age: 34,
        eventage: "3034",
        label: "30 - 34",
        abbr: "30-34",
    },
    {
        min_age: 35,
        max_age: 39,
        eventage: "3539",
        label: "35 - 39",
        abbr: "35-39",
    },
    {
        min_age: 40,
        max_age: 44,
        eventage: "4044",
        label: "40 - 44",
        abbr: "40-44",
    },
    {
        min_age: 45,
        max_age: 49,
        eventage: "4549",
        label: "45 - 49",
        abbr: "45-49",
    },
    {
        min_age: 50,
        max_age: 54,
        eventage: "5054",
        label: "50 - 54",
        abbr: "50-54",
    },
    {
        min_age: 55,
        max_age: 59,
        eventage: "5559",
        label: "55 - 59",
        abbr: "55-59",
    },
    {
        min_age: 60,
        max_age: 64,
        eventage: "6064",
        label: "60 - 64",
        abbr: "60-64",
    },
    {
        min_age: 65,
        max_age: 69,
        eventage: "6569",
        label: "65 - 69",
        abbr: "65-69",
    },
    {
        min_age: 70,
        max_age: 74,
        eventage: "7074",
        label: "70 - 74",
        abbr: "70-74",
    },
    {
        min_age: 75,
        max_age: 79,
        eventage: "7579",
        label: "75 - 79",
        abbr: "75-79",
    },
    {
        min_age: 80,
        max_age: 84,
        eventage: "8084",
        label: "80 - 84",
        abbr: "80-84",
    },
];

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

// predefined date-fns formats
export const DATE_FORMAT = "MMM D, YYYY";
export const DATE_FORMAT_ALT = "MM/DD/YYYY";
export const DATE_FORMAT_API = "YYYY-MM-DD";
export const DATETIME_FORMAT = "MMM D, YYYY, h:mm aa";

export const VARSITY_CHOICES = [
    { key: "", value: "Open" },
    { key: "VR", value: "Varsity" },
    { key: "JV", value: "Junior Varsity" },
];
export const VARSITY_LABELS = {
    "": "Open",
    VR: "Varsity",
    JV: "Junior Varsity",
};

export const HS_CLASSES = [
    { hsclass: "SR", min_age: 17, max_age: 18, name: "Senior" },
    { hsclass: "JR", min_age: 16, max_age: 17, name: "Junior" },
    { hsclass: "SO", min_age: 15, max_age: 16, name: "Sophomore" },
    { hsclass: "FR", min_age: 14, max_age: 15, name: "Freshman" },
    { hsclass: "FO", min_age: 14, max_age: 16, name: "FR & SO" },
    { hsclass: "8", min_age: 13, max_age: 14, name: "8th grade" },
    { hsclass: "7", min_age: 12, max_age: 13, name: "7th grade" },
    { hsclass: "6", min_age: 11, max_age: 12, name: "6th grade" },
    // { key: "FY", value: "Grade 9" },
];
export const HS_CLASS_CHOICES = HS_CLASSES.map(({ hsclass, name }) => ({
    key: hsclass,
    value: name,
}));
export const HS_CLASS_LABELS = _.fromPairs(
    HS_CLASSES.map(({ hsclass, name }) => [hsclass, name]),
);

export const Z_INDEX_MODAL = 1120;

export const Z_INDEX_OVERLAY = Z_INDEX_MODAL + 1;

export const Z_INDEX_SELECT_STYLES = {
    menu: (base) => ({ ...base, zIndex: Z_INDEX_MODAL + 1 }),
    menuPortal: (base) => ({ ...base, zIndex: Z_INDEX_MODAL + 1 }),
};

export const GROUP_SELECT_STYLES = {
    groupHeading: (base) => ({
        ...base,
        marginBottom: 0,
        display: "flex",
        alignItems: "center",
        height: 30,
        color: "#757575",
        fontSize: 12,
        letterSpacing: 1,
    }),
};

export const ALLOW_EXCEED_WARNING = 1;
export const ALLOW_EXCEED_CHOICES = [
    { key: "0", value: "No" },
    { key: "1", value: "Yes, with warning" },
    { key: "2", value: "Yes, auto mark exhibition" },
];

export const TYPE_OF_SCHOOL = [
    { key: "public", value: "Public" },
    { key: "private", value: "Private" },
];

export const TYPE_OF_DEGREE = [
    { key: "associate", value: "Two-year" },
    { key: "bachelor", value: "Four-year" },
];

export const UNDERGRADUATE_STUDENT_BODY = [
    { key: "small", value: "Small (< 1,500)" },
    { key: "medium", value: "Medium (1,500 - 8,000)" },
    { key: "large", value: "Large (8,000 - 15,000)" },
    { key: "xlarge", value: "X-Large (> 15,000)" },
];

export const SCHOOL_SETTING = [
    { key: "city", value: "City" },
    { key: "town", value: "Town" },
    { key: "suburban", value: "Suburban" },
    { key: "rural", value: "Rural" },
];

export const TOTAL_SCHOOL_COST = [
    { key: "cheap_cost", value: "$0 - $30,000" },
    { key: "about_average_cost", value: "$30,000 - $50,000" },
    { key: "above_average_cost", value: "$50,000 - $65,000" },
    { key: "expensive_cost", value: "$65,000+" },
];

export const TOTAL_SCHOOL_COST_SHORT_NAMES = [
    { key: "cheap_cost", value: "$0 - $30k" },
    { key: "about_average_cost", value: "$30k - $50k" },
    { key: "above_average_cost", value: "$50k - $65k" },
    { key: "expensive_cost", value: "$65k+" },
];

export const NET_SCHOOL_PRICE = [
    { key: "cheap_price", value: "$0 - $16,000" },
    { key: "about_average_price", value: "$16,000 - $20,000" },
    { key: "above_average_price", value: "$20,000 - $25,000" },
    { key: "expensive_price", value: "$25,000 - $30,000" },
    { key: "very_expensive_price", value: "$30,000+" },
];

export const NET_SCHOOL_PRICE_SHORT_NAMES = [
    { key: "cheap_price", value: "$0 - $16k" },
    { key: "about_average_price", value: "$16k - $20k" },
    { key: "above_average_price", value: "$20k - $25k" },
    { key: "expensive_price", value: "$25k - $30k" },
    { key: "very_expensive_price", value: "$30k+" },
];

export const SCHOOL_ADMISSIONS_SELECTIVITY = [
    { key: "highly", value: "Most Selective (0% - 25% admitted)" },
    { key: "very", value: "Very Selective (25% - 60% admitted)" },
    { key: "moderately", value: "Selective (60% - 80% admitted)" },
    { key: "open", value: "Less selective (80%+ admitted)" },
];

export const TEAM_RANKING = [
    { key: "", value: "No preference" },
    { key: "10", value: "Top 10" },
    { key: "25", value: "Top 25" },
    { key: "50", value: "Top 50" },
];

export const SMALL_DIVISIONS = [
    { key: "div-1", value: "Division 1" },
    { key: "div-2", value: "Division 2" },
    { key: "div-3", value: "Division 3" },
];

export const BIG_DIVISIONS = [
    { key: "div-4", value: "NAIA" },
    { key: "div-5", value: "NJCAA" },
    { key: "div-6", value: "CCCAA" },
];

export const TEAM_FIT_POSITION = [
    { key: "top1", value: "Fastest on the team" },
    { key: "top4", value: "Member of the conference team (2-4th)" },
    { key: "top8", value: "Not the slowest (5-8th)" },
];

export const TEAM_FIT_POSITION_SHORT_NAMES = [
    { key: "top1", value: "1st" },
    { key: "top4", value: "2nd - 4th" },
    { key: "top8", value: "5th - 8th" },
];

export const SORT_SCHOOLS_OPTIONS = [
    { key: "alpha", value: "Alphabetically" },
    { key: "match_score", value: "Match score" },
    { key: "cost", value: "Total cost" },
    { key: "distance", value: "Distance" },
    { key: "athletic", value: "Team strength" },
    { key: "academic", value: "Admit rate" },
    { key: "net_cost", value: "Net Cost" },
];

export const COLOR_PRIMARY = "#1565c0";

export const SWIMMER_SEARCH_COUNTRY_CHOICES = [
    { key: "", value: "All" },
    { key: "1", value: "United States" },
    { key: "2", value: "International" },
];

export const INDIVIDUAL_LIST_WITH_EMPTY_CHOICE = [
    { key: "", value: "-------" },
    ...INDIVIDUAL_LIST,
];

export const SWIMMER_HEIGHT_CHOICES = [
    { key: "", value: `-------` },
    { key: "48_inch", value: `4' 0"` },
    { key: "49_inch", value: `4' 1"` },
    { key: "50_inch", value: `4' 2"` },
    { key: "51_inch", value: `4' 3"` },
    { key: "52_inch", value: `4' 4"` },
    { key: "53_inch", value: `4' 5"` },
    { key: "54_inch", value: `4' 6"` },
    { key: "55_inch", value: `4' 7"` },
    { key: "56_inch", value: `4' 8"` },
    { key: "57_inch", value: `4' 9"` },
    { key: "58_inch", value: `4' 10"` },
    { key: "59_inch", value: `4' 11"` },
    { key: "60_inch", value: `5' 0"` },
    { key: "61_inch", value: `5' 1"` },
    { key: "62_inch", value: `5' 2"` },
    { key: "63_inch", value: `5' 3"` },
    { key: "64_inch", value: `5' 4"` },
    { key: "65_inch", value: `5' 5"` },
    { key: "66_inch", value: `5' 6"` },
    { key: "67_inch", value: `5' 7"` },
    { key: "68_inch", value: `5' 8"` },
    { key: "69_inch", value: `5' 9"` },
    { key: "70_inch", value: `5' 10"` },
    { key: "71_inch", value: `5' 11"` },
    { key: "72_inch", value: `6' 0"` },
    { key: "73_inch", value: `6' 1"` },
    { key: "74_inch", value: `6' 2"` },
    { key: "75_inch", value: `6' 3"` },
    { key: "76_inch", value: `6' 4"` },
    { key: "77_inch", value: `6' 5"` },
    { key: "78_inch", value: `6' 6"` },
    { key: "79_inch", value: `6' 7"` },
    { key: "80_inch", value: `6' 8"` },
    { key: "81_inch", value: `6' 9"` },
    { key: "82_inch", value: `6' 10"` },
    { key: "83_inch", value: `6' 11"` },
];

export const SWIMMER_WEIGHT_CHOICES = [
    { key: "", value: "-------" },
    { key: "100_lb", value: "100 lbs." },
    { key: "105_lb", value: "105 lbs." },
    { key: "110_lb", value: "110 lbs." },
    { key: "115_lb", value: "115 lbs." },
    { key: "120_lb", value: "120 lbs." },
    { key: "125_lb", value: "125 lbs." },
    { key: "130_lb", value: "130 lbs." },
    { key: "135_lb", value: "135 lbs." },
    { key: "140_lb", value: "140 lbs." },
    { key: "145_lb", value: "145 lbs." },
    { key: "150_lb", value: "150 lbs." },
    { key: "155_lb", value: "155 lbs." },
    { key: "160_lb", value: "160 lbs." },
    { key: "165_lb", value: "165 lbs." },
    { key: "170_lb", value: "170 lbs." },
    { key: "175_lb", value: "175 lbs." },
    { key: "180_lb", value: "180 lbs." },
    { key: "185_lb", value: "185 lbs." },
    { key: "190_lb", value: "190 lbs." },
    { key: "195_lb", value: "195 lbs." },
    { key: "200_lb", value: "200 lbs." },
    { key: "205_lb", value: "205 lbs." },
    { key: "210_lb", value: "210 lbs." },
    { key: "215_lb", value: "215 lbs." },
    { key: "220_lb", value: "220 lbs." },
    { key: "225_lb", value: "225 lbs." },
    { key: "230_lb", value: "230 lbs." },
    { key: "235_lb", value: "235 lbs." },
    { key: "240_lb", value: "240 lbs." },
    { key: "245_lb", value: "245 lbs." },
];

export const SWIMMER_SHOE_SIZE_CHOICES = [
    { key: "", value: "-------" },
    { key: "1.0_us", value: "1.0 US" },
    { key: "1.5_us", value: "1.5 US" },
    { key: "2.0_us", value: "2.0 US" },
    { key: "2.5_us", value: "2.5 US" },
    { key: "3.0_us", value: "3.0 US" },
    { key: "3.5_us", value: "3.5 US" },
    { key: "4.0_us", value: "4.0 US" },
    { key: "4.5_us", value: "4.5 US" },
    { key: "5.0_us", value: "5.0 US" },
    { key: "5.5_us", value: "5.5 US" },
    { key: "6.0_us", value: "6.0 US" },
    { key: "6.5_us", value: "6.5 US" },
    { key: "7.0_us", value: "7.0 US" },
    { key: "7.5_us", value: "7.5 US" },
    { key: "8.0_us", value: "8.0 US" },
    { key: "8.5_us", value: "8.5 US" },
    { key: "9.0_us", value: "9.0 US" },
    { key: "9.5_us", value: "9.5 US" },
    { key: "10.0_us", value: "10.0 US" },
    { key: "10.5_us", value: "10.5 US" },
    { key: "11.0_us", value: "11.0 US" },
    { key: "11.5_us", value: "11.5 US" },
    { key: "12.0_us", value: "12.0 US" },
    { key: "12.5_us", value: "12.5 US" },
    { key: "13.0_us", value: "13.0 US" },
    { key: "13.5_us", value: "13.5 US" },
    { key: "14.0_us", value: "14.0 US" },
    { key: "14.5_us", value: "14.5 US" },
    { key: "15.0_us", value: "15.0 US" },
    { key: "15.5_us", value: "15.5 US" },
    { key: "16.0_us", value: "16.0 US" },
];

export const SWIMMER_FIELD_LABELS = {
    address: "Address",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    gradhs: "Class",
    weight: "Weight",
    height: "Height",
    shoesize: "Shoe Size",
    phonehome: "Home phone",
    phonemobile: "Cell phone",
    act: "ACT",
    gpa: "GPA",
    satmath: "SAT Math",
    satverbal: "SAT Verbal",
    satwriting: "SAT Writing",
    toefl: "TOEFL",
    major: "Intended Major",
    academichonors: "Academic Honors",
    swimminghonors: "Swimming Honors",
    facebook: "Facebook",
    twitter: "Twitter",
};

export const SORT_SWIMMERS_OPTIONS = [
    { key: "powerindex", value: "Power index" },
    { key: "name", value: "Name" },
    { key: "date_favorited", value: "Date favorited" },
    { key: "date_interested", value: "Date interested" },
    { key: "date_hidden", value: "Date hidden" },
    { key: "top_time", value: "Top time" },
    { key: "act", value: "ACT" },
    { key: "sat", value: "SAT" },
    { key: "gpa", value: "GPA" },
    { key: "height", value: "Height" },
    { key: "weight", value: "Weight" },
    { key: "shoesize", value: "Shoe size" },
];

export const SORT_FAVORITES_SWIMMERS_OPTIONS = [
    { key: "date_favorited", value: "Date favorited" },
    { key: "powerindex", value: "Power index" },
    { key: "name", value: "Name" },
];

export const SORT_INTERESTED_SWIMMERS_OPTIONS = [
    { key: "date_interested", value: "Date interested" },
    { key: "powerindex", value: "Power index" },
    { key: "name", value: "Name" },
];

export const SORT_HIDDEN_SWIMMERS_OPTIONS = [
    { key: "date_hidden", value: "Date hidden" },
    { key: "powerindex", value: "Power index" },
    { key: "name", value: "Name" },
];

export const MEET_CUSTOM_SECTION_PRIVACY_TYPES = [
    { key: "0", value: "Public (visible to everyone)" },
    { key: "1", value: "Private (visible to coaches only)" },
];

export const RELAY_POSITION_STROKE = {
    1: { 1: "1", 2: "1", 3: "1", 4: "1" },
    5: { 1: "2", 2: "3", 3: "4", 4: "1" },
    6: { 1: "1", 2: "1", 3: "1", 4: "1" },
    7: { 1: "2", 2: "3", 3: "4", 4: "1" },
};

export const EVENTROUND_ORDER = {
    PRE: 1,
    SOP: 2,
    OCT: 3,
    SOO: 4,
    QUA: 5,
    SOQ: 6,
    SEM: 7,
    SOS: 8,
    SOF: 9,
    FIN: 10,
    TIM: 11,
};

export const COACH_TITLES = [
    { key: "1", value: "Head Coach" },
    { key: "2", value: "Head Coach, Women's Swimming" },
    { key: "3", value: "Head Coach, Men's Swimming" },
    { key: "4", value: "Associate Head Coach" },
    { key: "21", value: "Associate Head Coach, Women's Swimming" },
    { key: "22", value: "Associate Head Coach, Men's Swimming" },
    { key: "5", value: "Diving Coach" },
    { key: "23", value: "Head Swimming and Diving Coach" },
    { key: "6", value: "Assistant Coach" },
    { key: "7", value: "Assistant Coach, Women's Swimming" },
    { key: "8", value: "Assistant Coach, Men's Swimming" },
    { key: "9", value: "Assistant Coach, Recruiting Coordinator" },
    { key: "10", value: "Assistant Coach, Diving" },
    { key: "11", value: "Assistant Coach, Strength & Conditioning" },
    { key: "12", value: "Volunteer Assistant Coach" },
    { key: "13", value: "Volunteer Assistant Coach, Diving" },
    { key: "14", value: "Graduate Assistant Coach" },
    { key: "15", value: "Director of Operations" },
    { key: "16", value: "Administrative Assistant" },
    { key: "17", value: "Aquatic Director" },
    { key: "18", value: "Media Relations Contact" },
    { key: "19", value: "Meet Manager" },
    { key: "24", value: "Meet Director" },
    { key: "25", value: "Entry Chair" },
    { key: "26", value: "Athletic Trainer" },
    { key: "27", value: "Massage Therapist" },
    { key: "28", value: "Photographer" },
    { key: "29", value: "Administrator" },
    { key: "20", value: "Other" },
];

export const EVENTROUND_SWIMOFF = {
    PRE: "SOP", // Prelims => Prelims Swimoff
    OCT: "SOO", // Octofinals => Octofinals Swimoff
    QUA: "SOQ", // Quarterfinals => Quarterfinals Swimoff
    SEM: "SOS", // Semifinals => Semifinals Swimoff
};

export const SWIMOFF_EVENTROUND = {
    SOP: "PRE", // Prelims Swimoff => Prelims
    SOO: "OCT", // Octofinals Swimoff => Octofinals
    SOQ: "QUA", // Quarterfinals Swimoff => Quarterfinals
    SOS: "SEM", // Semifinals Swimoff => Semifinals
};

export const DESKTOP_APP_NAME = "Swimcloud Meet";

export const ALLOWED_TAGS = [
    "span",
    "p",
    "h2",
    "h1",
    "br",
    "strong",
    "em",
    "u",
    "ul",
    "ol",
    "li",
    "blockquote",
    "a",
];

export const DEFAULT_ROSTER_PAGE = 1;
export const DEFAULT_ROSTER_NAME = "";
export const DEFAULT_ROSTER_GENDER = null;
export const DEFAULT_ROSTER_ATTENDING = false;
export const DEFAULT_ROSTER_USA_MEMBERSHIP = "all";
export const DEFAULT_ROSTER_QUALIFYING = false;
export const DEFAULT_ROSTER_JUNIOR_VARSITY = false;
export const DEFAULT_ROSTER_VARSITY = false;
export const DEFAULT_ROSTER_AGEGROUP = "UNOV";
export const DEFAULT_ROSTER_ACTIVE_TAG_ID = null;
export const DEFAULT_ROSTER_TAGS = [];
export const DEFAULT_SELECTION = getDefaultSelection();

export const DEFAULT_COACH_ROSTER_TAGS = [];
export const DEFAULT_COACH_ROSTER_ACTIVE_TAG_ID = null;

export const DEFAULT_ROUND = "TIM";
export const DEFAULT_EVENTAGE = "UNOV";

export const CHANGE_TYPES = {
    CREATE_SPLASH: "CREATE_SPLASH",
    UPDATE_SPLASH: "UPDATE_SPLASH",
    DELETE_SPLASH: "DELETE_SPLASH",
};

export const STATUS_SUCCESS = 0;
export const STATUS_WARNING = 1;
export const STATUS_DANGER = 2;

export const EMAIL_MAX_QUERY_LENGTH = -1;

export const MIN_NAV_HEIGHT = 300; // 300px

export const MAX_HEAT = 100;

export const BACKUP_MAX_GAP = 0.3;

export const DEFAULT_VARSITY = "";

export const ENTRIES_GROUP_EVENT = "event";
export const ENTRIES_GROUP_SWIMMER = "swimmer";
export const ENTRIES_GROUP_ERROR = "error";

export const ENTRIES_ORDER_PLACE = "place";
export const ENTRIES_ORDER_SWIMMER = "swimmer";
export const ENTRIES_ORDER_TEAM = "team";
export const ENTRIES_ORDER_SESSION = "session";
export const ENTRIES_ORDER_NUMBER = "number";
export const ENTRIES_ORDER_EVENT = "event";
export const ENTRIES_ORDER_NUMERIC_ID = "numericId";
export const ENTRIES_ORDER_SEEDTIME = "seedtime";
export const ENTRIES_ORDER_EVENTTIME = "eventtime";

export const ENTRIES_GROUP_OPTIONS = [
    { key: ENTRIES_GROUP_EVENT, value: "Event" },
    { key: ENTRIES_GROUP_SWIMMER, value: "Swimmer" },
    { key: ENTRIES_GROUP_ERROR, value: "Error" },
];

export const DEFAULT_SCRATCH_DEADLINE = 30 * 60 * 1000;

export const EVENT_STATUS_ORDER = [
    "unmatched",
    "upcoming",
    "not_seeded",
    "seeded",
    "in_progress",
    "completed",
    "scratches_open",
    "scored",
];

export const ERROR_COLOR_MAP = {
    info: "u-color-info",
    warning: "u-color-warning",
    error: "u-color-danger",
};

export const TIMECODE_STAGES = {
    entries_open: "entries_open",
    entries_closed: "entries_closed",
    seeded: "seeded",
    in_progress: "in_progress",
    completed: "completed",
};

export const ERROR_LEVEL_ORDER = ["info", "warning", "error"];
export const ERROR_LABEL_MAP = {
    info: "message",
    warning: "warning",
    error: "error",
};

export const SPLIT_DISTANCES = [25, 50, 100];

export const CALCULATION_SUBTRACTIVE = "subtractive";
export const CALCULATION_CUMULATIVE = "cumulative";
export const CALCULATION_OPTIONS = [
    { key: CALCULATION_SUBTRACTIVE, value: "Subtractive" },
    { key: CALCULATION_CUMULATIVE, value: "Cumulative" },
];
export const CALCULATION_MAP = _.fromPairs(
    CALCULATION_OPTIONS.map(({ key, value }) => [key, value]),
);

export const RELAY_MODAL_SORT_BY_NAME = "name";
export const RELAY_MODAL_SORT_BY_TIME = "season_best";
export const RELAY_MODAL_SORT_BY_SPLIT = "1";
export const RELAY_MODAL_SORT_BY_OPTIONS = [
    { key: RELAY_MODAL_SORT_BY_TIME, value: "Best time" },
    { key: RELAY_MODAL_SORT_BY_SPLIT, value: "Relay split" },
    { key: RELAY_MODAL_SORT_BY_NAME, value: "Name" },
];
export const RELAY_MODAL_SORT_BY_MAP = _.fromPairs(
    RELAY_MODAL_SORT_BY_OPTIONS.map(({ key, value }) => [key, value]),
);

export const RELAY_MODAL_USE_AGGREGATE_TIME = "aggregate_time";
export const RELAY_MODAL_USE_BEST_TIME = "best_time";
export const RELAY_MODAL_USE_TIME_OPTIONS = [
    { key: RELAY_MODAL_USE_AGGREGATE_TIME, value: "aggregate time" },
    { key: RELAY_MODAL_USE_BEST_TIME, value: "best time" },
];
export const RELAY_MODAL_USE_TIME_MAP = _.fromPairs(
    RELAY_MODAL_USE_TIME_OPTIONS.map(({ key, value }) => [key, value]),
);

export const MEET_COLLABORATE_QUALIFYING_START_LOCAL_KEY =
    "@meetCollaborate/qulifyingStart";

export const MEET_COLLABORATE_QUALIFYING_END_LOCAL_KEY =
    "@meetCollaborate/qulifyingEnd";

export const MEET_MANAGE_QUALIFYING_START_LOCAL_KEY =
    "@meetManage/qulifyingStart";

export const MEET_MANAGE_QUALIFYING_END_LOCAL_KEY = "@meetManage/qulifyingEnd";

export const EXTENDED_EVENT_CHOICES = [
    { key: "125", value: "25 Free" },
    { key: "150", value: "50 Free" },
    { key: "160", value: "60 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1400", value: "400 Free" },
    { key: "1500", value: "500 Free" },
    { key: "1800", value: "800 Free" },
    { key: "11000", value: "1000 Free" },
    { key: "11500", value: "1500 Free" },
    { key: "11650", value: "1650 Free" },
    { key: "225", value: "25 Back" },
    { key: "250", value: "50 Back" },
    { key: "2100", value: "100 Back" },
    { key: "2200", value: "200 Back" },
    { key: "325", value: "25 Breast" },
    { key: "350", value: "50 Breast" },
    { key: "3100", value: "100 Breast" },
    { key: "3200", value: "200 Breast" },
    { key: "425", value: "25 Fly" },
    { key: "450", value: "50 Fly" },
    { key: "4100", value: "100 Fly" },
    { key: "4200", value: "200 Fly" },
    { key: "5100", value: "100 IM" },
    { key: "5160", value: "160 IM" },
    { key: "5200", value: "200 IM" },
    { key: "5400", value: "400 IM" },
    { key: "6100", value: "100 Free Relay" },
    { key: "6160", value: "160 Free Relay" },
    { key: "6200", value: "200 Free Relay" },
    { key: "6250", value: "250 Free Relay" },
    { key: "6320", value: "320 Free Relay" },
    { key: "6400", value: "400 Free Relay" },
    { key: "6800", value: "800 Free Relay" },
    { key: "7100", value: "100 Medley Relay" },
    { key: "7160", value: "160 Medley Relay" },
    { key: "7200", value: "200 Medley Relay" },
    { key: "7300", value: "300 Medley Relay" },
    { key: "7400", value: "400 Medley Relay" },
    { key: "H1", value: "1M Diving" },
    { key: "H3", value: "3M Diving" },
    { key: "H2", value: "Platform Diving" },
];

export const SCY_EVENT_CHOICES = [
    { key: "125", value: "25 Free" },
    { key: "150", value: "50 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1500", value: "500 Free" },
    { key: "11000", value: "1000 Free" },
    { key: "11650", value: "1650 Free" },
    { key: "225", value: "25 Back" },
    { key: "250", value: "50 Back" },
    { key: "2100", value: "100 Back" },
    { key: "2200", value: "200 Back" },
    { key: "325", value: "25 Breast" },
    { key: "350", value: "50 Breast" },
    { key: "3100", value: "100 Breast" },
    { key: "3200", value: "200 Breast" },
    { key: "425", value: "25 Fly" },
    { key: "450", value: "50 Fly" },
    { key: "4100", value: "100 Fly" },
    { key: "4200", value: "200 Fly" },
    { key: "5100", value: "100 IM" },
    { key: "5200", value: "200 IM" },
    { key: "5400", value: "400 IM" },
    { key: "6100", value: "100 Free Relay" },
    { key: "6200", value: "200 Free Relay" },
    { key: "6400", value: "400 Free Relay" },
    { key: "6800", value: "800 Free Relay" },
    { key: "7100", value: "100 Medley Relay" },
    { key: "7200", value: "200 Medley Relay" },
    { key: "7400", value: "400 Medley Relay" },
    { key: "H1", value: "1M Diving" },
    { key: "H3", value: "3M Diving" },
    { key: "H2", value: "Platform Diving" },
];

export const SCM_EVENT_CHOICES = [
    { key: "125", value: "25 Free" },
    { key: "150", value: "50 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1400", value: "400 Free" },
    { key: "1800", value: "800 Free" },
    { key: "11500", value: "1500 Free" },
    { key: "225", value: "25 Back" },
    { key: "250", value: "50 Back" },
    { key: "2100", value: "100 Back" },
    { key: "2200", value: "200 Back" },
    { key: "325", value: "25 Breast" },
    { key: "350", value: "50 Breast" },
    { key: "3100", value: "100 Breast" },
    { key: "3200", value: "200 Breast" },
    { key: "425", value: "25 Fly" },
    { key: "450", value: "50 Fly" },
    { key: "4100", value: "100 Fly" },
    { key: "4200", value: "200 Fly" },
    { key: "5100", value: "100 IM" },
    { key: "5200", value: "200 IM" },
    { key: "5400", value: "400 IM" },
    { key: "6100", value: "100 Free Relay" },
    { key: "6200", value: "200 Free Relay" },
    { key: "6400", value: "400 Free Relay" },
    { key: "6800", value: "800 Free Relay" },
    { key: "7100", value: "100 Medley Relay" },
    { key: "7200", value: "200 Medley Relay" },
    { key: "7400", value: "400 Medley Relay" },
    { key: "H1", value: "1M Diving" },
    { key: "H3", value: "3M Diving" },
    { key: "H2", value: "Platform Diving" },
];

export const LCM_EVENT_CHOICES = [
    { key: "150", value: "50 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1400", value: "400 Free" },
    { key: "1800", value: "800 Free" },
    { key: "11500", value: "1500 Free" },
    { key: "225", value: "25 Back" },
    { key: "250", value: "50 Back" },
    { key: "2100", value: "100 Back" },
    { key: "2200", value: "200 Back" },
    { key: "350", value: "50 Breast" },
    { key: "3100", value: "100 Breast" },
    { key: "3200", value: "200 Breast" },
    { key: "450", value: "50 Fly" },
    { key: "4100", value: "100 Fly" },
    { key: "4200", value: "200 Fly" },
    { key: "5200", value: "200 IM" },
    { key: "5400", value: "400 IM" },
    { key: "6200", value: "200 Free Relay" },
    { key: "6400", value: "400 Free Relay" },
    { key: "6800", value: "800 Free Relay" },
    { key: "7200", value: "200 Medley Relay" },
    { key: "7400", value: "400 Medley Relay" },
    { key: "H1", value: "1M Diving" },
    { key: "H3", value: "3M Diving" },
    { key: "H2", value: "Platform Diving" },
];

export const XCY_EVENT_CHOICES = [
    { key: "160", value: "60 Free" },
    { key: "1100", value: "100 Free" },
    { key: "1200", value: "200 Free" },
    { key: "1500", value: "500 Free" },
    { key: "2100", value: "100 Back" },
    { key: "3100", value: "100 Breast" },
    { key: "4100", value: "100 Fly" },
    { key: "5160", value: "160 IM" },
    { key: "5200", value: "200 IM" },
    { key: "6160", value: "160 Free Relay" },
    { key: "6200", value: "200 Free Relay" },
    { key: "6320", value: "320 Free Relay" },
    { key: "7100", value: "100 Medley Relay" },
    { key: "7160", value: "160 Medley Relay" },
];

export const OPN_EVENT_CHOICES = [
    { key: "11000", value: "1K" },
    { key: "12000", value: "2K" },
    { key: "13000", value: "3K" },
    { key: "15000", value: "5K" },
    { key: "17500", value: "7.5K" },
    { key: "110000", value: "10K" },
    { key: "125000", value: "25K" },
];

export const EVENT_TIMES_LAYOUTS = {
    entries: "entries",
    heats: "heats",
    results: "results",
};

export const FLY_DQ_CODES = [
    { key: "1A", value: "Alternating kick" },
    { key: "1B", value: "Breaststroke type kick/feet turned out" },
    { key: "1C", value: "Scissors kick/feet not on same horizontal plane" },
    { key: "1D", value: "Arms--underwater recovery" },
    { key: "1E", value: "Non-simultaneous arms" },
    { key: "1F", value: "One hand touch" },
    { key: "1G", value: "No hand touch" },
    { key: "1H", value: "Non-simultaneous touch" },
    { key: "1I", value: "Body--not on breast during swim" },
    { key: "1J", value: "15-meter violation" },
];

export const BACK_DQ_CODES = [
    { key: "2A", value: "Delay initiating arm pull once towards breast" },
    {
        key: "2B",
        value: "Delay initiating turn after arm pull once towards breast",
    },
    { key: "2C", value: "More than one arm pull while on breast" },
    { key: "2D", value: "Not on back off wall" },
    { key: "2E", value: "Not on back at finish" },
    { key: "2F", value: "Not on back during swim" },
    { key: "2G", value: "No touch at turn" },
    { key: "2H", value: "Toes curled over gutter after the start" },
    { key: "2I", value: "Completely submerged prior to/at turn or finish" },
    { key: "2J", value: "15-meter violation" },
];

export const BREAST_DQ_CODES = [
    { key: "3A", value: "Alternating kick" },
    { key: "3B", value: "Foot/Feet not turned out" },
    { key: "3C", value: "Scissors kick/feet not on same horizontal plane" },
    { key: "3D", value: "Downward butterfly kick" },
    { key: "3E", value: "Non-simultaneous arms" },
    { key: "3F", value: "One hand touch" },
    { key: "3G", value: "No hand touch" },
    { key: "3H", value: "Non-simultaneous touch" },
    { key: "3I", value: "Arms not pushed forward from breast" },
    { key: "3J", value: "Hands brought beyond the hipline during stroke" },
    { key: "3K", value: "Arms not in same horizontal plane 1st arm pull/swim" },
    { key: "3L", value: "Elbows recovered over water" },
    { key: "3M", value: "Two or more strokes under water" },
    {
        key: "3N",
        value: "Stroke cycle out of order, other than one pull followed by one kick",
    },
    { key: "3O", value: "Body not on breast off turn" },
];

export const FREE_DQ_CODES = [
    { key: "4G", value: "No touch on turn" },
    { key: "4J", value: "15-meter violation" },
];

export const IM_DQ_CODES = [
    { key: "5A", value: "Stroke infraction(use DQ for stroke)" },
    { key: "5B", value: "Strokes out of sequence" },
];

export const RELAY_DQ_CODES = [
    { key: "61", value: "Stroke Infraction swimmer #1" },
    { key: "62", value: "Stroke Infraction swimmer #2" },
    { key: "63", value: "Stroke Infraction swimmer #3" },
    { key: "64", value: "Stroke Infraction swimmer #4" },
    { key: "66", value: "Early take-off swimmer #2" },
    { key: "67", value: "Early take-off swimmer #3" },
    { key: "68", value: "Early take-off swimmer #4" },
    { key: "6C", value: "Changed order of swimmers" },
    { key: "6D", value: "Not enough swimmers" },
    { key: "6E", value: "Failed to exit immediately" },
    { key: "6F", value: "Re-entered before race was over" },
    { key: "6G", value: "Medley Relay - Strokes out of order" },
    {
        key: "6H",
        value: "Medley Relay - 4th stroke repeat of first three strokes",
    },
];

export const MISCELLANEOUS_DQ_CODES = [
    { key: "7A", value: "False start" },
    { key: "7B", value: "Declared false start" },
    { key: "7C", value: "Delay of meet" },
    { key: "7D", value: "Did not finish" },
    { key: "7E", value: "Entered water without permission" },
    { key: "7F", value: "Interfered with another swimmer" },
    { key: "7G", value: "Walking on or springing from bottom" },
    { key: "7H", value: "Pulling on lane line" },
    { key: "7I", value: "Finish in wrong lane" },
    { key: "7J", value: "No show for previous race (penalty event)" },
    { key: "7X", value: "Illegal swimsuit" },
    { key: "7Y", value: "Unsportsmanlike conduct" },
    { key: "7Z", value: "Other" },
];

export const DQ_CODES = {
    1: FREE_DQ_CODES,
    2: BACK_DQ_CODES,
    3: BREAST_DQ_CODES,
    4: FLY_DQ_CODES,
    5: IM_DQ_CODES,
    6: RELAY_DQ_CODES,
    7: RELAY_DQ_CODES,
};

export const DEFAULT_TEAMS_PAGE = 1;
export const DEFAULT_TOP_SWIMMERS_PAGE = 1;
export const DEFAULT_TOP_SWIMS_PAGE = 1;

export const DEFAULT_TIMES_GENDER = C_GENDER_OPTION_ALT.key;
export const DEFAULT_TIMES_AGE_GROUP = {
    id: "All",
    name: "All age groups",
    eventage: "All age groups",
};

export const JSON_API_ATOMIC_HEADER =
    'application/vnd.api+json;ext="https://jsonapi.org/ext/atomic"';

export const MEET_CHANGE = "meet.change";

export const SYNC_MODELS = [
    "Meet",
    "MeetRoster",
    "Splash",
    "Relay",
    "MeetHeat",
    "MeetTeamEntriesSubmit",
    "SplashComment",
    "MeetEventRound",
    "CoachRoster",
    "CTSRace",
    "SSTRace",
    "DAKRace",
    "TDRRace",
    "DOLRace",
    "MeetConnectLog",
    "MeetDualScore",
];

export const CLICKABLE_SPLASH_ERRORS = [
    "BACKUP_ERROR",
    "MISSING_PAD",
    "NUM_LEGS",
    "ALTERNATE_ENTRY",
    "NO_CUT",
    "ERRONEOUS_RELAYLEG",
];

export const LIVE_HEAT_SCOREBOARD_SCENE_ID = "live_heat";
export const TEAM_SCORES_SCOREBOARD_SCENE_ID = "team_scores";
export const EVENT_RESULTS_SCOREBOARD_SCENE_ID = "event_results";

export const DEFAULT_SCOREBOARD_SCENES = [
    { id: LIVE_HEAT_SCOREBOARD_SCENE_ID, name: "Live heat" },
    { id: TEAM_SCORES_SCOREBOARD_SCENE_ID, name: "Team scores" },
    { id: EVENT_RESULTS_SCOREBOARD_SCENE_ID, name: "Event results" },
];

export const DEFAULT_SCENE = DEFAULT_SCOREBOARD_SCENES[0];
export const DEFAULT_SCENE_FILTERS = { showHeatTeam: true };

export const DEFAULT_SCOREBOARD_CONFIG = {
    selectedScene: DEFAULT_SCENE,
    selectedFilters: DEFAULT_SCENE_FILTERS,
};

export const OPPONENT_RESULT_MAP = {
    W: "L",
    L: "W",
    T: "T",
};

export const ALTERNATIVE_EVENT_ID_OPTIONS_MAP = {
    "1400L": [{ key: "1500Y", value: "500 Free SCY" }],
    "1400S": [{ key: "1500Y", value: "500 Free SCY" }],
    "1500Y": [
        { key: "1400L", value: "400 Free LCM" },
        { key: "1400S", value: "400 Free SCM" },
    ],
    "1800L": [{ key: "11000Y", value: "1000 Free SCY" }],
    "1800S": [{ key: "11000Y", value: "1000 Free SCY" }],
    "11000Y": [
        { key: "1800L", value: "800 Free LCM" },
        { key: "1800S", value: "800 Free SCM" },
    ],
    "11500L": [{ key: "11650Y", value: "1650 Free SCY" }],
    "11500S": [{ key: "11650Y", value: "1650 Free SCY" }],
    "11650Y": [
        { key: "11500L", value: "1500 Free LCM" },
        { key: "11500S", value: "1500 Free SCM" },
    ],
};

export const ALLOWED_NUMBER_OF_FREE_RACES = 10;
